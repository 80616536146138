import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "underscore";
import header from "../../assets/images/header.jpeg";
import instruction1 from "../../assets/images/instruction1.jpeg";
import instruction2 from "../../assets/images/instruction2.jpeg";
import rodape from "../../assets/images/rodape.jpeg";
import api from "../../services/api";
import "./styles.css";
export function Home() {
  const [viewLink, setViewLink] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [signature, setSignature] = useState();
  const [message, setMessage] = useState("");
  const [extraMessage, setExtraMessage] = useState("");

  const [questionCorretly, setQuestionCorretly] = useState([]);
  const [questionNotCorretly, setQuestionNotCorretly] = useState([]);
  const [dataState, setData] = useState({});
  const [modalCongratsIsOpen, setModalCongratsIsOpen] = useState(false);

  const signatureCanvasRef = React.useRef();

  const [questions, setQuestions] = useState([]);
  useEffect(() => {
    loadQuestions();

    // getPlayer();
  }, []);
  const handleSave = () => {
    const canvas = signatureCanvasRef.current.getCanvas();
    const dataURL = canvas.toDataURL(); // Obtém a representação da imagem em formato de URL base64
    console.log(dataURL);
    setSignature(dataURL);
    setMessage("Assinatura salva com sucesso!");
    // Aqui você pode enviar a URL base64 para o servidor ou fazer qualquer outra ação necessária
  };

  async function loadQuestions() {
    setLoading(true);

    await api
      .get("/auth/getQuizExpedicao")
      .then((res) => {
        console.log("res", res.data.user.actionData);
        setQuestions(res.data.user.actionData.questions);
        console.log(questions);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }

  const {
    handleSubmit,

    formState: { errors },
  } = useForm();

  let timer;

  document.addEventListener("input", (e) => {
    const el = e.target;

    if (el.matches("[data-color]")) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        document.documentElement.style.setProperty(
          `--color-${el.dataset.color}`,
          el.value,
        );
      }, 100);
    }
  });
  function maskCPF(v) {
    return new Promise((resolve, reject) => {
      //61422834310
      //614.228.343-10
      v = v.replace(/\D/g, "");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
      setData((prevstate) => ({
        ...prevstate,
        cpf: v,
      }));
      resolve(v);
    });
  }

  function maskPlaca(v) {
    return new Promise((resolve, reject) => {
      console.log(v);
      //61422834310
      //614.228.343-10
      v = v.replace(/[A-Z]{3}[0-9]{1}[A-Z]{1}[0-9]{2}|[A-Z]{3}[0-9]{4}/g);
      // v = v.replace(/([AAA])(\s)$/, '$1-$2');

      setData((prevstate) => ({
        ...prevstate,
        placa: v,
      }));
      resolve(v);
      console.log("vvvvvvvvvv", v);
    });
  }

  function maskPhone(v) {
    return new Promise((resolve, reject) => {
      //61422834310
      //614.228.343-10
      v = v.replace(/\D/g, "");
      v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
      v = v.replace(/(\d)(\d{4})$/, "$1-$2");
      setData((prevstate) => ({
        ...prevstate,
        tel: v,
      }));
      resolve(v);
    });
  }
  function handleChange(e, index) {
    const { name, value } = e.target;
    console.log(questions);
    setQuestions((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        choosenAnswer: value,
      },
    }));
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(e.target);
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function toggleModal(questionCorretly, questionNotCorretly, dataState) {
    setQuestionCorretly(questionCorretly);
    setQuestionNotCorretly(questionNotCorretly);
    setModalCongratsIsOpen(!modalCongratsIsOpen);
  }
  async function sendForm() {
    console.log(dataState);
    setLoading(true);
    const isSignatureEmpty = signatureCanvasRef.current.isEmpty();
    let questionCorretly = [];
    let questionNotCorretly = [];
    if (!viewLink) {
      return toast.error("Você ainda não visualizou o documento... ");
    }
    if (isSignatureEmpty) {
      return toast.error("Assine o formulário", "error");
    }
    if (!signature) {
      return toast.error(
        "Assine o formulário e clique em salvar formulário",
        "error",
      );
    }
    if (!checkbox) {
      return toast.error("Você precisa aceitar os termos", "error");
    }
    let questionTotal = "";
    _.map(questions, (question, index) => {
      if (question.choosenAnswer === question.rightAnswer) {
        questionCorretly.push(question);
      } else {
        questionNotCorretly.push(question);
      }
      questionTotal = question[index];
    });
    setQuestionCorretly(questionCorretly);
    setQuestionNotCorretly(questionNotCorretly);
    const percent =
      (questionCorretly.length * 100) / Object.keys(questions).length;
    if (percent >= 100) {
      const params = {
        // city: dataState.city,
        cpf: dataState.cpf,
        empresa: dataState.empresa,
        name: dataState.name,
        // placa: dataState.placa,
        // state: dataState.state,
        // phone: dataState.tel,
        signature,
        questionCorretly,
        questionNotCorretly,
        extraMessage,
      };
      await api
        .post("/auth/register_denuncia", params)
        .then((res) => {
          setLoading(false);
          setModalCongratsIsOpen(!modalCongratsIsOpen);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    } else {
      setLoading(false);
      setModalCongratsIsOpen(!modalCongratsIsOpen);
    }
  }

  const clear = () => {
    signatureCanvasRef.current.clear(); // Limpa o conteúdo do canvas
  };

  function renderModal() {
    const percent =
      (questionCorretly.length * 100) / Object.keys(questions).length;

    return (
      <div>
        <Modal
          isOpen={modalCongratsIsOpen}
          onRequestClose={() => toggleModal()}
        >
          <div>
            <img src={header} alt="" width="100%" />
          </div>
          <div className=" m-top-20">
            <div className="col-10 m-auto white ">
              {loading === false && (
                <div className="title  text-center ">
                  {percent >= 100 && (
                    <>
                      <h3>Parabéns! 😃</h3>
                      <br />
                      <h3>Você acertou</h3>
                      <br />
                    </>
                  )}
                  {percent <= 60 && (
                    <>
                      <div className="color-red">
                        <h3>Você não foi qualificado</h3>
                        <br />
                        <h3>acertou apenas...</h3>
                        <br />
                      </div>
                    </>
                  )}

                  <div className="col-2 text-center center justify-content-center m-auto py-4">
                    <CircularProgressbarWithChildren
                      value={percent}
                      text={`${percent.toFixed(1)}%`}
                      className="circular-bar-oq time-oq"
                      maxValue={100}
                      styles={{
                        // Customize the root svg element
                        root: {},
                        // Customize the path, i.e. the "completed progress"
                        path: {
                          // Path color
                          // stroke: `rgba(62, 152, 199, ${80 / 100})`,
                          stroke: `linear-gradient(90deg, rgba(0,105,217,1) 0%, rgba(164,209,102,1) 74%, rgba(164,209,102,1) 100%);`,
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",
                          // Customize transition animation
                          transition: "stroke-dashoffset 0.5s ease 0s",
                          // Rotate the path
                          // transform: 'rotate(0.50turn)',
                          transformOrigin: "center center",
                        },
                        // Customize the circle behind the path, i.e. the "total progress"
                        trail: {
                          // Trail color
                          stroke: "#d6d6d6",
                          // stroke: `linear-gradient(90deg, rgba(0,105,217,1) 0%, rgba(164,209,102,1) 74%, rgba(164,209,102,1) 100%);`,

                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          // strokeLinecap: 'butt',
                          // Rotate the trail
                          // transform: 'rotate(0.25turn)',
                          // transformOrigin: 'center center',
                        },
                        // Customize the text
                        text: {
                          // Text color
                          fill: "rgba(0,105,217,1)",
                          // Text size
                          fontSize: "16px",
                        },
                        // Customize background - only used when the `background` prop is true
                        background: {
                          fill: "#rgba(0,105,217,1)",
                        },
                      }}
                    >
                      {/* <img src={time} alt='' width='75%' /> */}
                    </CircularProgressbarWithChildren>
                  </div>
                  {percent >= 100 && (
                    <div className="justify-content-center text-center d-flex pb-3">
                      <button
                        onClick={() => {
                          window.location.href = "/finish";
                        }}
                        className="button justify-content-center text-center"
                      >
                        Finalizar
                      </button>
                    </div>
                  )}
                  {percent < 100 && (
                    <div className="justify-content-center text-center d-flex pb-3">
                      <button
                        onClick={() => {
                          toggleModal(
                            questionCorretly,
                            questionNotCorretly,
                            dataState,
                          );
                        }}
                        className="button justify-content-center text-center"
                      >
                        Refazer Formulário
                      </button>
                    </div>
                  )}
                </div>
              )}
              {loading === true && (
                <div className="title  text-center ">
                  <div class="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <div className="oq-wrapper-contents oq-center">
            <h1 className="oq-font-medium title-alert">Parabéns</h1>

            <p className="oq-font-book content-alert">
              Seu resgate foi feito com sucesso! Em até dias o dinheiro estará
              na sua conta.
            </p>
          </div>
          <div className="oq-group-horizontal">
            <button className="oq-btn btn-confirm">Confirmar</button>
          </div> */}
        </Modal>
      </div>
    );
  }
  const handleClickLinkPDF = (event) => {
    event.preventDefault(); // Evita que o link seja seguido

    // Seu código personalizado aqui
    console.log("O usuário clicou no link!");
    setViewLink(true);

    window.open(
      "https://firebasestorage.googleapis.com/v0/b/formcazanga.appspot.com/o/Co%CC%81digo%20de%20Conduta_Revisa%CC%83o%201.pdf?alt=media&token=c41cea95-5f6b-4104-b8b3-45b5f2834872",
      "_blank",
    );
  };

  return (
    <>
      <div>
        <img src={header} alt="" width="100%" />
      </div>
      <div className=" m-top-20 container">
        <div className="col-10 m-auto white ">
          <div className="title  text-center mx-3 fa-bold">
            <br />
            <p>
              Antes de prosseguir, precisamos que você assista ao vídeo para
              responder as perguntas abaixo.{" "}
            </p>
            <div className="">
              <h3>Código de Conduta</h3>
              <div className="video-container ">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/ovfOX7UQTps?si=onny0IOrDpEDb51-"
                  title="YouTube video player"
                  frameBorder="0"
                  webkitallowFullScreen
                  moza-llowFullScreen
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="my-4">
              <img src={instruction1} alt="" width="100%" />
            </div>
            <div className=" mx-5 pb-4 d-flex justify-content-center mt-3">
              <div className="text-center">
                <p className="fs-4 fw-bold">Código de Conduta Cazanga</p>
                <button
                  onClick={handleClickLinkPDF}
                  className="button-save justify-content-center text-center"
                  rel="noreferrer"
                >
                  Abrir Link
                </button>
              </div>
            </div>
            <div className="my-4">
              <img src={instruction2} alt="" width="100%" />
            </div>

            <div className="">
              <h3>Canal de denúncias</h3>
              <div className="video-container ">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.loom.com/embed/4515541191b645ff914532a5f16436ae?sid=d0b4a7f0-6e5f-4637-a8a6-2896ad39809d"
                  title="YouTube video player"
                  frameBorder="0"
                  webkitallowFullScreen
                  moza-llowFullScreen
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="mt-4">
              <h3>Como fazer uma denúncia</h3>
              <div className="video-container">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.loom.com/embed/96c0469781b143daa826e3007f4a92d7?sid=2fe3cb62-533d-4668-83d7-85d3f475086e"
                  title="YouTube video player"
                  frameBorder="0"
                  webkitallowFullScreen
                  moza-llowFullScreen
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="mt-4">
              <h3>Como consultar o protocolo</h3>
              <div className="video-container">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.loom.com/embed/4671f98163c84a229e92923daa637658?sid=56e69386-6921-4905-8ab7-b0be74f4d90e"
                  title="YouTube video player"
                  frameBorder="0"
                  webkitallowFullScreen
                  moza-llowFullScreen
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
          {/* <div className="col-10 d-flex  m-auto">
            <img src={instrucoes} alt="" width={"100%"} />
          </div> */}

          <br />
          <div className="col-10  pb-4 m-auto justify-content-center d-flex flex-column mt-4">
            <p className="  fs-4">
              Agora, precisamos da sua identificação para responder o formulário
              abaixo
            </p>
            <form onSubmit={handleSubmit(sendForm)}>
              <div className="">
                <label className="input">
                  <input
                    className="input__field"
                    type="text"
                    placeholder=" "
                    onChange={(e) => handleInputChange(e)}
                    required={true}
                    name="name"
                    value={dataState && dataState.name}
                    // {...register('name', { required: true })}
                  />
                  <span className="input__label">Nome Completo</span>
                  {errors.name && (
                    <span className="alert-danger">
                      Esse campo é obrigatório
                    </span>
                  )}
                </label>
              </div>
              <div className="my-3">
                <label className="input">
                  <input
                    className="input__field"
                    type="text"
                    placeholder=" "
                    onChange={(e) => maskCPF(e.target.value)}
                    required={true}
                    maxLength={14}
                    value={dataState && dataState.cpf}
                    // {...register('cpf', {
                    //   required: true,
                    // })}
                  />
                  <span className="input__label">CPF</span>
                  {errors.cpf && (
                    <span className="alert-danger">
                      Esse campo é obrigatório
                    </span>
                  )}
                </label>
              </div>
              {/* <div className="my-3">
                <label className="input">
                  <input
                    className="input__field"
                    type="text"
                    placeholder=" "
                    onChange={(e) => maskPhone(e.target.value)}
                    required={true}
                    maxLength={15}
                    name="tel"
                    value={dataState && dataState.tel}
                  />
                  <span className="input__label">Telefone</span>
                  {errors.tel && (
                    <span className="alert-danger">
                      Esse campo é obrigatório
                    </span>
                  )}
                </label>
              </div> */}
              <div className="my-3">
                <label className="input">
                  <input
                    className="input__field"
                    type="text"
                    placeholder=" "
                    onChange={(e) => handleInputChange(e)}
                    required={true}
                    name="empresa"
                    value={dataState && dataState.empresa}
                    // {...register('empresa', { required: true })}
                  />
                  <span className="input__label">Empresa</span>
                  {errors.empresa && (
                    <span className="alert-danger">
                      Esse campo é obrigatório
                    </span>
                  )}
                </label>
              </div>
              {/* <div className="my-3">
                <label className="input">
                  <input
                    className="input__field"
                    type="text"
                    placeholder=" "
                    onChange={(e) => handleInputChange(e)}
                    required={true}
                    name="state"
                    value={dataState && dataState.state}
                    // {...register('state', { required: true })}
                  />
                  <span className="input__label">Estado</span>
                  {errors.state && (
                    <span className="alert-danger">
                      Esse campo é obrigatório
                    </span>
                  )}
                </label>
              </div> */}
              {/* <div className="my-3">
                <label className="input">
                  <input
                    className="input__field"
                    type="text"
                    placeholder=" "
                    onChange={(e) => handleInputChange(e)}
                    required={true}
                    name="city"
                    value={dataState && dataState.city}
                    // {...register('city', { required: true })}
                  />
                  <span className="input__label">Cidade</span>
                  {errors.city && (
                    <span className="alert-danger">
                      Esse campo é obrigatório
                    </span>
                  )}
                </label>
              </div> */}
              {/* <div className="my-3">
                <label className="input">
                  <input
                    className="input__field"
                    type="text"
                    placeholder=" "
                    onChange={(e) => maskPlaca(e.target.value)}
                    required={true}
                    name="placa"
                    value={dataState && dataState.placa}
                    // {...register('placa', { required: true })}
                  />
                  <span className="input__label">Placa do Veículo</span>
                  {errors.placa && (
                    <span className="alert-danger">
                      Esse campo é obrigatório
                    </span>
                  )}
                </label>
              </div> */}
              {questionNotCorretly.length === 0 &&
                _.map(questions, (question, index) => {
                  return (
                    <div className="mb-3 flex flex-col " key={index}>
                      <p className="mb-3 fw-bold">
                        {parseInt(index) + parseInt(1)} - {question.question}
                      </p>
                      <div>
                        <label className="p-3-right" htmlFor="">
                          <input
                            className="p-3-right"
                            type="radio"
                            value="A"
                            onChange={(e) => {
                              handleChange(e, index);
                            }}
                            checked={question.choosenAnswer === "A"}
                          />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: question.answerA,
                            }}
                          ></span>
                          {/* {question.answerA} */}
                        </label>
                      </div>
                      <div>
                        <label className="p-3-right mt-1" htmlFor="">
                          <input
                            className="p-3-right"
                            type="radio"
                            value="B"
                            id=""
                            onChange={(e) => {
                              handleChange(e, index);
                            }}
                            checked={question.choosenAnswer === "B"}
                          />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: question.answerB,
                            }}
                          ></span>
                        </label>
                      </div>
                      <div>
                        <label className="p-3-right mt-1" htmlFor="">
                          <input
                            className="p-3-right"
                            type="radio"
                            value="C"
                            id=""
                            onChange={(e) => {
                              handleChange(e, index);
                            }}
                            checked={question.choosenAnswer === "C"}
                          />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: question.answerC,
                            }}
                          ></span>
                        </label>
                      </div>
                      <div>
                        <label className="p-3-right mt-1" htmlFor="">
                          <input
                            className="p-3-right"
                            type="radio"
                            value="D"
                            id=""
                            onChange={(e) => {
                              handleChange(e, index);
                            }}
                            checked={question.choosenAnswer === "D"}
                          />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: question.answerD,
                            }}
                          ></span>
                        </label>
                      </div>
                      <br />
                    </div>
                  );
                })}

              {questionNotCorretly &&
                questionNotCorretly.length > 0 &&
                _.map(questions, (question, index) => {
                  const isQuestionNotCorrectly = questionNotCorretly.some(
                    (notCorrectQuestion) =>
                      notCorrectQuestion.question === question.question,
                  );

                  return (
                    <div
                      className={` mb-4 ${
                        isQuestionNotCorrectly ? "color-red" : ""
                      }`}
                      key={index}
                    >
                      <p className="mb-3 fw-bold">
                        {parseInt(index) + parseInt(1)} - {question.question}
                      </p>
                      <div>
                        <label className="p-3-right" htmlFor="">
                          <input
                            className="p-3-right"
                            type="radio"
                            value="A"
                            onChange={(e) => {
                              handleChange(e, index);
                            }}
                            checked={question.choosenAnswer === "A"}
                          />
                          {question.answerA}
                        </label>
                      </div>

                      <div>
                        <label className="p-3-right" htmlFor="">
                          <input
                            className="p-3-right"
                            type="radio"
                            value="B"
                            id=""
                            onChange={(e) => {
                              handleChange(e, index);
                            }}
                            checked={question.choosenAnswer === "B"}
                          />
                          {question.answerB}
                        </label>
                      </div>

                      <div>
                        <label className="p-3-right" htmlFor="">
                          <input
                            className="p-3-right"
                            type="radio"
                            value="C"
                            id=""
                            onChange={(e) => {
                              handleChange(e, index);
                            }}
                            checked={question.choosenAnswer === "C"}
                          />
                          {question.answerC}
                        </label>
                      </div>

                      <div>
                        <label className="p-3-right mt-1" htmlFor="">
                          <input
                            className="p-3-right"
                            type="radio"
                            value="D"
                            id=""
                            onChange={(e) => {
                              handleChange(e, index);
                            }}
                            checked={question.choosenAnswer === "D"}
                          />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: question.answerD,
                            }}
                          ></span>
                        </label>
                      </div>
                      <br />
                    </div>
                  );
                })}

              {/* {questionNotCorretly &&
                questionNotCorretly.length > 0 &&
                _.map(questions, (question, index) => {
                  console.log('questionNotCorretly', questionNotCorretly);
                  return (
                    <div className="my-3 mb-4 color-red" key={index}>
                      <p>
                        {parseInt(index) + parseInt(1)} - {question.question}
                      </p>

                      <label className="p-3-right" htmlFor="">
                        <input
                          className="p-3-right"
                          type="radio"
                          value="A"
                          onChange={(e) => {
                            handleChange(e, index);
                          }}
                          checked={question.choosenAnswer === 'A'}
                        />
                        {question.answerA}
                      </label>

                      <label className="p-3-right" htmlFor="">
                        <input
                          className="p-3-right"
                          type="radio"
                          value="B"
                          id=""
                          onChange={(e) => {
                            handleChange(e, index);
                          }}
                          checked={question.choosenAnswer === 'B'}
                        />
                        {question.answerB}
                      </label>
                      <br />
                    </div>
                  );
                })} */}
              <div className="mt-4 mb-2 justify-content-center text-center d-flex flex-column">
                <p>
                  Caso tenha ficado alguma dúvida sobre os temas tratados, deixe
                  aqui a sua pergunta{" "}
                </p>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  value={extraMessage}
                  onChange={(e) => setExtraMessage(e.target.value)}
                ></textarea>
              </div>

              <div className="mt-4 mb-2 justify-content-center text-center d-flex flex-column">
                <p>Assine seu nome usando o dedo </p>
                <div className="d-flex justify-content-center">
                  <SignatureCanvas
                    penColor="black"
                    dotSize={1}
                    throttle={0}
                    canvasProps={{
                      className: "sigCanvas",
                    }}
                    className={"justify-content-center text-center d-flex "}
                    clearOnResize={false}
                    ref={signatureCanvasRef}
                  />
                </div>
                <p className={`me-5 ${message !== "" ? "signature" : ""}`}>
                  {message !== "" ? message : ""}
                </p>
                <div className="d-flex  align-items-center justify-content-center">
                  <div
                    className="button-save justify-content-center text-center"
                    onClick={handleSave}
                  >
                    Salvar Assinatura
                  </div>
                  {signature !== "" && (
                    <button
                      className="button-clear justify-content-center text-center"
                      onClick={() => clear()}
                      type="button"
                    >
                      Limpar
                    </button>
                  )}
                </div>

                <br />
                <div className="text-left mb-4">
                  <input
                    type="checkbox"
                    className="me-2 "
                    onChange={() => setCheckbox(!checkbox)}
                    checked={checkbox}
                  />
                  <span className="fs-6">
                    Estou ciente de que todo conteúdo, material e informações
                    constantes no Programa de Integridade são de domínio
                    exclusivo da Calcário Cazanga, sendo vedado, portanto,
                    qualquer forma de reprodução ou plágio. Estou ciente também
                    de que cópias impressas dos documentos tem caráter apenas
                    informativo, a versão atualizada está sob controle do SGQ da
                    empresa.
                  </span>
                </div>
              </div>

              <div className="justify-content-center text-center d-flex">
                <input
                  type="submit"
                  className="button justify-content-center text-center"
                  value={"Enviar Formulário"}
                ></input>
              </div>
            </form>
            {renderModal()}
          </div>
        </div>
      </div>
      <div className="mx-auto ">
        <img src={rodape} alt="" width={"auto"} className="mx-auto w-100" />
      </div>
    </>
  );
}
