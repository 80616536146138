import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import "react-circular-progressbar/dist/styles.css";
import header from "../../assets/images/header.jpeg";
import "./styles.css";
export function Finish() {
  const [open, setOpen] = useState(false);
  const [questionCorretly, setQuestionCorretly] = useState([]);
  const [questionNotCorretly, setQuestionNotCorretly] = useState([]);
  const [dataState, setData] = useState({});
  const [modalCongratsIsOpen, setModalCongratsIsOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [rightQuestions, setRightQuestions] = useState({
    number1: "Nao",
    number2: "Nao",
    number3: "29",
    number4: "Nao",
    number5: "Nao",
    number6: "Nao",
    number7: "Sim",
    number8: "Sim",
    number9: "Fechadas",
    number10: "Cintas",
    number11: "Nao",
    number12: "Sim",
  });
  const [questions, setQuestions] = useState({
    number1: "",
    number2: "",
    number3: "",
    number4: "",
    number5: "",
    number6: "",
    number7: "",
    number8: "",
    number9: "",
    number10: "",
    number11: "",
    number12: "",
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  let timer;

  document.addEventListener("input", (e) => {
    const el = e.target;

    if (el.matches("[data-color]")) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        document.documentElement.style.setProperty(
          `--color-${el.dataset.color}`,
          el.value,
        );
      }, 100);
    }
  });

  return (
    <>
      <div>
        <img src={header} alt="" width="100%" />
      </div>
      <div className=" m-top-20">
        <div className="col-10 m-auto white ">
          <div className="title  text-center">
            {/* <h3>Questionário - Motorista Qualificado</h3> */}
            <br />
          </div>
          <br />
          <div className="col-10 pb-4 m-auto text-center">
            <h3>Obrigado por responder o formulário! 😃</h3>
          </div>
        </div>
      </div>
    </>
  );
}
